export const LOGOUT = "LOGOUT";
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

export const SET_USERS_REQUEST = "SET_USERS_REQUEST";
export const SET_USERS_SUCCESS = "SET_USERS_SUCCESS";
export const SET_USERS_FAILURE = "SET_USERS_FAILURE";
export const SET_ADD_USER_REQUEST = "SET_ADD_USER_REQUEST";
export const SET_ADD_USER_SUCCESS = "SET_ADD_USER_SUCCESS";
export const SET_ADD_USER_FAILURE = "SET_ADD_USER_FAILURE";
export const SET_UPDATE_USER_REQUEST = "SET_UPDATE_USER_REQUEST";
export const SET_UPDATE_USER_SUCCESS = "SET_UPDATE_USER_SUCCESS";
export const SET_UPDATE_USER_FAILURE = "SET_UPDATE_USER_FAILURE";
export const SET_DELETE_USER_REQUEST = "SET_DELETE_USER_REQUEST";
export const SET_DELETE_USER_SUCCESS = "SET_DELETE_USER_SUCCESS";
export const SET_DELETE_USER_FAILURE = "SET_DELETE_USER_FAILURE";

export const ADD_PROPERTY_REQUEST = "ADD_PROPERTY_REQUEST";
export const ADD_PROPERTY_SUCCESS = "ADD_PROPERTY_SUCCESS";
export const ADD_PROPERTY_FAILURE = "ADD_PROPERTY_FAILURE";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAILURE = "UPDATE_PROPERTY_FAILURE";

export const FETCH_PROPERTIES_REQUEST = "FETCH_PROPERTIES_REQUEST";
export const FETCH_PROPERTIES_SUCCESS = "FETCH_PROPERTIES_SUCCESS";
export const FETCH_PROPERTIES_FAILURE = "FETCH_PROPERTIES_FAILURE";

export const FETCH_PROPERTY_REQUEST = "FETCH_PROPERTY_REQUEST";
export const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS";
export const FETCH_PROPERTY_FAILURE = "FETCH_PROPERTY_FAILURE";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";

export const SET_DASHBOARD_DATA_REQUEST = "SET_DASHBOARD_DATA_REQUEST";
export const SET_DASHBOARD_DATA_SUCCESS = "SET_DASHBOARD_DATA_SUCCESS";
export const SET_DASHBOARD_DATA_FAILURE = "SET_DASHBOARD_DATA_FAILURE";

export const SET_MULTI_TAB_DATA_REQUEST = "SET_MULTI_TAB_DATA_REQUEST";
export const SET_MULTI_TAB_DATA_SUCCESS = "SET_MULTI_TAB_DATA_SUCCESS";
export const SET_MULTI_TAB_DATA_FAILURE = "SET_MULTI_TAB_DATA_FAILURE";

export const ADD_NOTES_REQUEST = "ADD_NOTES_REQUEST";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_FAILURE = "ADD_NOTES_FAILURE";
export const SET_UPDATE_NOTE_REQUEST = "SET_UPDATE_NOTE_REQUEST";
export const SET_UPDATE_NOTE_SUCCESS = "SET_UPDATE_NOTE_SUCCESS";
export const SET_UPDATE_NOTE_FAILURE = "SET_UPDATE_NOTE_FAILURE";

export const SET_PROPERTY_NOTES_REQUEST = "SET_PROPERTY_NOTES_REQUEST";
export const SET_PROPERTY_NOTES_SUCCESS = "SET_PROPERTY_NOTES_SUCCESS";
export const SET_PROPERTY_NOTES_FAILURE = "SET_PROPERTY_NOTES_FAILURE";

export const SET_SELECTED_PROPERTIES = "SET_SELECTED_PROPERTIES";
